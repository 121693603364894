/* Import a retro, pixelated font */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  font-family: 'Press Start 2P', cursive;
  color: #fff;
}

/* Animated background gradient cycling through multiple colors, slowed down */
/* (Remove if using Vanta.js Fog as background) */
body {
  background: none; /* Removed gradient background so only the Fog effect is visible */
}

/* Container for your React app */
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding-top: 80px;
}

.content {
  text-align: center;
  max-width: 650px;
  margin: 0 auto;
  padding: 20px;
}

/* Header: Align head image to the left of the speech bubble */
.header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
}

.head-image {
  width: 80px;
  height: auto;
  margin-right: 10px;
  /* Continuous intermittent head shake animation (3s cycle) */
  animation: shake 3s infinite;
}

/* Speech bubble styling with fixed dimensions, left padding, and a tail.
   The bubble is shifted slightly down. */
.speech-bubble {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 235px;
  height: 60px;
  background: #fff;
  color: #121212;
  border-radius: 20px;
  font-size: 0.7rem;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  margin-top: 15px;
  padding-left: 25px;
  padding-right: 10px;
  text-align: left;
  /* Continuous intermittent bubble giggle animation (2s cycle) */
  animation: giggle 2s infinite;
}

/* Speech bubble tail using clip-path, stretched a bit */
.speech-bubble::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 10px;
  width: 30px;
  height: 55px;
  background: #fff;
  clip-path: polygon(0 100%, 100% 50%, 0 0);
}

/* Intro text styling */
.intro {
  font-size: 1rem;
  margin-bottom: 40px;
  line-height: 1.5;
}

/* Current Projects Section styling for pixel art style */
.projects-section {
  margin: 40px 0;
}

.section-title {
  font-size: 1rem;
  margin-bottom: 20px;
  text-decoration: underline;
}

.project-cards-container {
  display: flex;
  justify-content: center;
  gap: 60px;
  flex-wrap: wrap;
}

.project-card {
  background: #121212;
  color: #fff;
  border: 2px solid #fff;
  padding: 15px;
  width: 260px;
  text-align: left;
  box-shadow: 3px 3px 0 #fff;
  image-rendering: pixelated;
  display: flex;
  flex-direction: column;
  height: 100px; /* Fixed height for alignment */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 5px 5px 0 #fff;
}

.project-card h3 {
  margin: 0 0 10px;
  font-size: 0.9rem;
}

.project-card p {
  margin: 0;
  font-size: 0.7rem;
  margin-bottom: 10px;
}

/* Progress meter styling for pixel art style */
.progress-meter {
  position: relative;
  width: 100%;
  height: 15px;
  background: #333;
  border: 2px solid #fff;
  box-shadow: 2px 2px 0 #fff;
  margin-top: auto; /* Static position at bottom of the card */
}

.progress-fill {
  height: 100%;
  transition: width 0.5s ease;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.7rem;
  color: #fff;
}

/* Contact button styling */
.contact {
  margin-top: 40px;
}

.contact-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #fff;
  color: #121212;
  text-decoration: none;
  font-size: 0.8rem;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.contact-btn:hover {
  background-color: #e0e0e0;
  transform: scale(1.05);
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
}

/* Footer styling with minimal padding and centered icons */
.footer {
  text-align: center;
  padding: 5px 0;
}

.footer-icon {
  font-size: 1rem;
  margin: 0 5px;
}

/* Keyframes for intermittent head shake animation */
@keyframes shake {
  0% { transform: translate(0, 0) rotate(0deg); }
  10% { transform: translate(-2px, 2px) rotate(-2deg); }
  20% { transform: translate(0, 0) rotate(0deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}

/* Keyframes for intermittent speech bubble giggle animation */
@keyframes giggle {
  0% { transform: translate(0, 0) rotate(0deg); }
  10% { transform: translate(0, -2px) rotate(2deg); }
  20% { transform: translate(0, 0) rotate(0deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}

/* --- Hover text swap for Inventus card --- */
.inventus-card .hover-link {
  display: none;
}

.inventus-card:hover .default-desc {
  display: none;
}

.inventus-card:hover .hover-link {
  display: block;
}

/* Force the link inside hover text to be green */
.inventus-card:hover .hover-link a {
  color: #00cc66 !important;
}
